<template>
  <div class="container">
    <div v-if="client">
      <div class="row mb-0">
        <div class="col-auto my-auto">
          <h5 class="mb-0 client-tab-heading">Details</h5>
        </div>
        <div class="col text-end">
          <button
            v-if="!client.welcome_email_sent"
            class="btn btn-outline-primary btn-sm"
            @click="showWelcomeEmailModal"
          >
            <i class="far fa-hand-sparkles me-1"></i>
            Send Welcome Email
          </button>

          <p v-else class="badge bg-light">
            <i class="far fa-check-circle me-1"></i>
            Welcome Email Sent
          </p>
        </div>
      </div>

      <!--  -->
      <hr class="my-3" />

      <form @submit.prevent="updateClientInfo">
        <div class="row mb-3">
          <div class="col-6">
            <label for="fname">First Name*</label>
            <input
              type="text"
              id="fname"
              class="form-control"
              v-model="clientCopy.first_name"
              @change="updateClientInfo"
              placeholder="First name"
              required
            />
          </div>
          <div class="col-6">
            <label for="lname">Last Name*</label>
            <input
              type="text"
              id="lname"
              class="form-control"
              v-model="clientCopy.last_name"
              @change="updateClientInfo"
              placeholder="Last name"
              required
            />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="email">Email Address*</label>
            <input
              type="email"
              id="email"
              class="form-control"
              v-model="clientCopy.email"
              @change="updateClientInfo"
              placeholder="Email address"
              :disabled="clientCopy.parent_id"
              required
            />
          </div>

          <div class="col-6">
            <label for="dob">Date of Birth</label>
            <date-input
              id="dob"
              :show-formatted="true"
              v-model="clientCopy.dob"
              @change="updateClientInfo"
            ></date-input>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="gender">Gender</label>
            <select
              id="gender"
              v-model="clientCopy.gender"
              @change="updateClientInfo"
              class="form-control"
            >
              <option value>Select gender</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="non-binary">Non-binary</option>
            </select>
          </div>

          <div class="col-6">
            <label for="title">Personal Title</label>
            <select
              id="gender"
              v-model="clientCopy.title"
              @change="updateClientInfo"
              class="form-control"
            >
              <option value>Select title</option>
              <option value="mr">Mr</option>
              <option value="mrs">Mrs</option>
              <option value="miss">Miss</option>
              <option value="ms">Ms</option>
              <option value="mx">Mx</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="email">NHS Number</label>
            <input
              type="text"
              id="nhs_number"
              class="form-control"
              v-model="clientCopy.nhs_number"
              @change="updateClientInfo"
              maxlength="12"
              placeholder="123 456 7890"
            />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="phone">Phone Number (Primary)</label>
            <phone-number-input
              v-model="clientCopy.phone"
              default-country-code="GB"
              :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']"
              :clearable="true"
              @update="updatePhone"
            ></phone-number-input>
          </div>
          <div class="col-6">
            <label for="phone_2">Phone Number (Secondary)</label>
            <phone-number-input
              v-model="clientCopy.mobile"
              default-country-code="GB"
              :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']"
              :clearable="true"
              @update="updatePhone"
            ></phone-number-input>
          </div>
        </div>
        <div class="row">
          <div class="col text-end">
            <button
              class="btn btn-sm btn-outline-primary"
              @click="updateClientInfo"
            >
              <i class="far fa-save me-1"></i>
              Save
            </button>
          </div>
        </div>
      </form>
      <hr class="my-3" />
      <div class="row" v-if="addNewShippingAddress || addNewPrimaryAddress">
        <div class="col">
          <div class="alert bg-light">
            <div class="row mb-2">
              <div class="col">
                <h5 v-if="addNewShippingAddress">New Shipping Address</h5>
                <h5 v-if="addNewPrimaryAddress">New Primary Address</h5>
              </div>
              <div class="col text-end">
                <span
                  @click="closeNewAddress"
                  class="cursor-pointer"
                  v-tooltip:bottom="'Close'"
                >
                  <i class="far fa-times fa-2x"></i>
                </span>
              </div>
            </div>
            <form @submit.prevent="saveAddress">
              <div class="row mb-4" v-if="addNewShippingAddress">
                <div class="col">
                  <label for
                    >Address Title (this is for internal use only ie. Home,
                    Default, Work)*</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressTitle"
                    required
                  />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <label for>Address 1*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddress1"
                    required
                  />
                </div>
                <div class="col">
                  <label for>Address 2</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddress2"
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <label for>Town</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressTown"
                  />
                </div>
                <div class="col">
                  <label for>City*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressCity"
                    required
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <label for>County</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressCounty"
                  />
                </div>
                <div class="col">
                  <label for>Post Code*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressPostCode"
                    required
                  />
                </div>
                <div class="col">
                  <label for>Country*</label>
                  <country-select
                    v-model="newAddressCountry"
                    required
                  ></country-select>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col text-end">
                  <button class="btn btn-primary">
                    <i class="far fa-save me-2"></i>
                    <span v-if="addNewShippingAddress"
                      >Save Shipping Address</span
                    >
                    <span v-if="addNewPrimaryAddress"
                      >Save Primary Address</span
                    >
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="card border-0 shadow-sm bg-light w-100">
            <div class="card-header bg-primary text-white border-0">
              <span> <i class="fas fa-house me-2"></i>Primary Address </span>
              <span class="float-end">
                <button
                  class="btn btn-outline-light btn-sm"
                  :style="{
                    visibility:
                      !addNewPrimaryAddress &&
                      !addNewShippingAddress &&
                      !client.address
                        ? 'visible'
                        : 'hidden',
                  }"
                  @click="addNewPrimaryAddress = true"
                >
                  <i class="far fa-plus me-2"></i>New
                </button>
              </span>
            </div>
            <div class="card-body">
              <div v-if="clientCopy.address" class="row">
                <div class="col">
                  <address-inline
                    :a="clientCopy.address"
                    :show-delete="false"
                  ></address-inline>
                </div>
              </div>
              <div v-else-if="!addNewPrimaryAddress" class="row">
                <div class="col text-center">
                  <i class="fas fa-house fa-5x text-primary"></i>
                  <p class="mt-2">No primary address found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div class="card border-0 shadow-sm bg-light w-100">
            <div class="card-header bg-primary text-white border-0">
              <span>
                <i class="fas fa-shipping-fast me-2"></i>Shipping Addresses
              </span>
              <span class="float-end">
                <button
                  class="btn btn-outline-light btn-sm"
                  v-show="!addNewShippingAddress && !addNewPrimaryAddress"
                  @click="addNewShippingAddress = true"
                >
                  <i class="far fa-plus me-2"></i>New Shipping Address
                </button>
              </span>
            </div>
            <div class="card-body">
              <div v-if="clientCopy.shipping_addresses.length > 0" class="row">
                <div
                  class="col-6"
                  v-for="(a, index) in clientCopy.shipping_addresses"
                  :key="index"
                >
                  <address-inline :a="a"></address-inline>
                </div>
              </div>
              <div
                v-else-if="
                  clientCopy.shipping_addresses.length === 0 &&
                  !addNewShippingAddress
                "
                class="row"
              >
                <div class="col text-center">
                  <i class="fas fa-shipping-fast fa-5x text-primary"></i>
                  <p class="mt-2">No shipping addresses found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <welcome-email-modal :user="user" :client="client"></welcome-email-modal>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import AddressInline from "./address-partials/AddressInline";
import CountrySelect from "../../../globals/CountrySelect";
import WelcomeEmailModal from "./email-partials/WelcomeEmailModal";

export default {
  data() {
    return {
      client: null,
      addNewShippingAddress: false,
      addNewPrimaryAddress: false,
      newAddressTitle: "",
      newAddress1: "",
      newAddress2: "",
      newAddressTown: "",
      newAddressCity: "",
      newAddressCounty: "",
      newAddressPostCode: "",
      newAddressCountry: "",
      editingAdress: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    clientCopy() {
      return this.client;
    },
  },
  methods: {
    editAddress(a) {
      this.editingAdress = a;
    },
    closeNewAddress() {
      this.addNewShippingAddress = false;
      this.addNewPrimaryAddress = false;
    },
    saveAddress() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.clientCopy.id +
            "/add-address",
          {
            address_1: this.newAddress1,
            address_2: this.newAddress2,
            town: this.newAddressTown,
            city: this.newAddressCity,
            county: this.newAddressCounty,
            post_code: this.newAddressPostCode,
            country: this.newAddressCountry,
            label: this.newAddressTitle,
            type: this.addNewPrimaryAddress ? "client" : "shipping",
          }
        )
        .then(({ data }) => {
          this.addNewShippingAddress = false;
          this.addNewPrimaryAddress = false;
          this.newAddressTitle = "";
          this.newAddress1 = "";
          this.newAddress2 = "";
          this.newAddressTown = "";
          this.newAddressCity = "";
          this.newAddressCounty = "";
          this.newAddressPostCode = "";
          this.newAddressCountry = "";
          this.$EventBus.$emit("alert", data);

          //quickfix
          window.location = "/clients/" + this.clientCopy.id + "#details";
          window.location.reload();
        });
    },
    updateClientInfo() {
      const vm = this;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/clients/api/" + this.clientCopy.id,
          this.clientCopy
        )
        .then(({ data }) => {
          vm.updateCount += 1;
          this.$EventBus.$emit("refreshClient");
          this.$EventBus.$emit("alert", data);
        });
    },
    updatePhone(input) {
      if (input.isValid) {
        this.updateClientInfo();
      }
    },
    showWelcomeEmailModal() {
      $("#welcomeEmailModal").modal("show");
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    var vm = this;
    this.$EventBus.$on("addressRemoved", function (removed) {
      var addresses = vm.clientCopy.addresses;
      var newList = [];
      Object.keys(addresses).forEach((key) => {
        let address = addresses[key];
        if (address.id !== removed.id) {
          newList.push(address);
        }
      });
      vm.clientCopy.addresses = newList;
    });
  },
  components: {
    WelcomeEmailModal,
    CountrySelect,
    AddressInline,
  },
};
</script>

<style>
</style>
