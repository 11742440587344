<template>
  <div class="card border-0 mb-3">
    <div class="card-header bg-primary text-white border-0">
      <span>{{ a.label }}</span>
      <span class="float-end text-white">
        <small class="me-2" v-if="editingAdress && editingAdress.id == a.id"
          ><i>These fields auto-save</i></small
        >
        <button
          class="btn btn-outline-light btn-sm"
          v-if="editingAdress == null"
          @click="editAddress(a)"
        >
          <i class="far fa-edit text-white"></i>
        </button>
        <button
          class="btn btn-outline-danger btn-sm ms-2"
          v-if="showDelete && editingAdress == null"
          @click="deleteAddress(a)"
        >
          <i class="text-danger far fa-trash"></i>
        </button>

        <button
          class="d-inline btn btn-outline-secondary btn-sm"
          v-if="editingAdress && editingAdress.id == a.id"
          @click="editingAdress = null"
        >
          <i class="far fa-times"></i>
        </button>
      </span>
    </div>

    <div class="card-body py-1">
      <div class="row">
        <div class="col">
          <p
            class="my-2"
            :class="editingAdress && editingAdress.id == a.id ? 'd-none' : ''"
          >
            {{ a.address_1 }}
          </p>
          <label for v-if="editingAdress && editingAdress.id == a.id"
            >Address 1</label
          >
          <input
            type="text"
            v-if="editingAdress && editingAdress.id == a.id"
            v-model="a.address_1"
            class="form-control mb-3"
            @change="updateShippingAddress(a)"
          />

          <p
            class="my-2"
            :class="editingAdress && editingAdress.id == a.id ? 'd-none' : ''"
          >
            {{ a.address_2 }}
          </p>
          <label for v-if="editingAdress && editingAdress.id == a.id"
            >Address 2</label
          >
          <input
            type="text"
            v-if="editingAdress && editingAdress.id == a.id"
            v-model="a.address_2"
            class="form-control mb-3"
            @change="updateShippingAddress(a)"
          />

          <p
            class="my-2"
            :class="editingAdress && editingAdress.id == a.id ? 'd-none' : ''"
          >
            {{ a.town }}
          </p>
          <label for v-if="editingAdress && editingAdress.id == a.id"
            >Town</label
          >
          <input
            type="text"
            v-if="editingAdress && editingAdress.id == a.id"
            v-model="a.town"
            class="form-control mb-3"
            @change="updateShippingAddress(a)"
          />

          <p
            class="my-2"
            :class="editingAdress && editingAdress.id == a.id ? 'd-none' : ''"
          >
            {{ a.city }}
          </p>
          <label for v-if="editingAdress && editingAdress.id == a.id"
            >City</label
          >
          <input
            type="text"
            v-if="editingAdress && editingAdress.id == a.id"
            v-model="a.city"
            class="form-control mb-3"
            @change="updateShippingAddress(a)"
          />

          <p
            class="my-2"
            :class="editingAdress && editingAdress.id == a.id ? 'd-none' : ''"
          >
            {{ a.county }}
          </p>
          <label for v-if="editingAdress && editingAdress.id == a.id"
            >County</label
          >
          <input
            type="text"
            v-if="editingAdress && editingAdress.id == a.id"
            v-model="a.county"
            class="form-control mb-3"
            @change="updateShippingAddress(a)"
          />

          <p
            class="my-2"
            :class="editingAdress && editingAdress.id == a.id ? 'd-none' : ''"
          >
            {{ a.post_code }}
          </p>
          <label for v-if="editingAdress && editingAdress.id == a.id"
            >Post Code</label
          >
          <input
            type="text"
            v-if="editingAdress && editingAdress.id == a.id"
            v-model="a.post_code"
            class="form-control mb-3"
            @change="updateShippingAddress(a)"
          />

          <p
            class="my-2"
            :class="editingAdress && editingAdress.id == a.id ? 'd-none' : ''"
          >
            {{ a.country }}
          </p>
          <label for v-if="editingAdress && editingAdress.id == a.id"
            >Country</label
          >
          <country-select
            v-model="a.country"
            v-if="editingAdress && editingAdress.id == a.id"
            @change="updateShippingAddress(a)"
          ></country-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountrySelect from "../../../../globals/CountrySelect";
export default {
  props: {
    a: Object,
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editingAdress: null,
    };
  },
  methods: {
    editAddress(a) {
      this.editingAdress = a;
    },
    updateShippingAddress(address) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            address.id +
            "/update-address",
          address
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    deleteAddress(address) {
      var confirmed = confirm(
        "Are you sure you wish to delete this address, this action cannot be undone?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              address.id +
              "/delete-address",
            address
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$EventBus.$emit("addressRemoved", address);
          });
      }
    },
  },
  filters: {},
  computed: {},
  mounted() {},
  watch: {},
  components: {
    CountrySelect,
  },
};
</script>

<style>
</style>
